footer {
  position: relative;
  @include line-height(24);
  flex: 1;
  padding: 48px;
  z-index: 0;
  background-color: $blue;
  font-weight: 300;

  aio-footer {
    position: relative;
    z-index: 0;

    & > * {
      color: $white;
    }
  }

  .footer-block {
    margin: 0 24px;
    vertical-align: top;
  }

  a {
      color: $white;
      text-decoration: none;
      z-index: 20;
      position: relative;
      &:hover {
        text-decoration: underline;
      }
      &:visited {
        text-decoration: none;
      }
  }
  a.action {
    cursor: pointer;
  }
  h3 {
    @include font-size(16);
    text-transform: uppercase;
    font-weight: 400;
    margin: 0 0 16px;
    color: $white;
  }
  p {
    text-align: center;
    margin: 10px 0px 5px;

    @media (max-width: 480px) {
      text-align: left;
    }
  }

  div.grid-fluid {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;

    justify-content: center;

    text-align: left;
    margin: 0 0 40px;

    ul {
      list-style-position: inside;
      padding: 0px;
      margin: 0px;

      li {
        list-style-type: none;
        padding: 0px;
        text-align: left;
      }
    }

    @media (max-width: 480px) {
      flex-direction: column;
      .footer-block {
        margin: 8px 24px;
      }
    }
  }

  @media (max-width: 600px) {
    h3 {
      @include font-size(14);
    }
  }
}

footer::after {
    content: '';
    position: absolute;
    z-index: -1;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background:
        url('/assets/images/logos/angular/angular_whiteTransparent_withMargin.png') top 0 left 0 repeat,
        url('/assets/images/logos/angular/angular_whiteTransparent_withMargin.png') top 80px left 160px repeat;
    opacity: 0.05;
    background-size: 320px auto;
}
