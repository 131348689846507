.alert {
    padding: 16px;
    margin: 24px 0px;
    @include font-size(14);
    color: $darkgray;
    width: 100%;
    box-sizing: border-box;
    clear: both;

    h1, h2, h3, h4, h5, h6 {
      font-weight: 500;
    }

    &.is-critical {
        border-left: 8px solid $brightred;
        background-color: rgba($brightred, 0.05);

        h1, h2, h3, h4, h5, h6 {
            color: $brightred;
        }
    }

    &.is-important {
        border-left: 8px solid $orange;
        background-color: rgba($orange, 0.05);

        h1, h2, h3, h4, h5, h6 {
            color: $orange;
        }
    }

    &.is-helpful {
        border-left: 8px solid $blue;
        background-color: rgba($blue, 0.05);

        h1, h2, h3, h4, h5, h6 {
            color: $blue;
        }
    }

    &.archive-warning {
      background-color: $darkred;
      border-radius: 4px;
      margin-bottom: 1rem;

      * {
        color: $white;
      }

      a {
        color: $white;
        font-weight: bold;
        text-decoration: underline;

        &:hover {
          opacity: 0.9;
        }
      }
    }

    > * {
        margin: 8px 16px;
    }
}
