html, body {
    height: 100%;
}

body,
.content {
    background-color: $white;
}

.github-links + .content h1 {
  max-width: 90%;
}

.clearfix {
    content: "";
    display: table;
    clear: both;
}

.clear {
    clear: both;
}

.l-clearfix:after, .clearfix:after {
    content: "";
    display: table;
    clear: both;
}

.is-visible {
    display: block!important;
}

.l-flex-wrap {
  display: flex;
  flex-wrap: wrap;
}

.flex-center {
    display: flex;
    justify-content: center;
}

.center {
    text-align: center;
}

.visually-hidden {
    position: absolute !important;
    top: -9999px !important;
    left: -9999px !important;
}

.text-uppercase {
  text-transform: uppercase;
}
