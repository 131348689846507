.edit-page-cta {
    font-weight: 400;
    @include font-size(14);
    color: $blue;
    text-align: right;
    margin-right: 32px;
    display: block;
    position: absolute;
    right: 0;
}
