.nf-container {
    align-items: center;
    padding: 32px;
}

.nf-response {
    margin: 32px;
    height: 100%;
    flex-direction: column;

    h1 {
      @include font-size(48);
      color: $blue;
        text-transform: uppercase;
        margin: 8px 0;
    }
}

.nf-icon.material-icons {
    color: $blue;
    @include font-size(100);
    position: static;
}
