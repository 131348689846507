/*
 * General styling to make detail/summary tags look a bit more material
 * To get the best out of it you should structure your usage like this:
 *
 * ```
 * <details>
 *   <summary>Some title</summary>
 *   <div class="details-content">
 *     Some content
 *   </div>
 *  </details>
 *
 */

summary {
  cursor: pointer;
  @include font-size(16);
  position: relative;
  padding: 16px 24px;
  color: $black;
  height: 16px;
  display: block; // Remove the built in details marker in FF

  &::-webkit-details-marker {
    display: none; // Remove the built in details marker in webkit
  }

  overflow: hidden;
}

details {
  box-shadow: 0 1px 4px 0 rgba($black, 0.37);

  .detail-contents {
    padding: 16px 24px;
  }

  // Rotate the icon
  summary i.material-icons.expand {
    @include rotate(0deg);
  }
  &[open] > summary i.material-icons.expand {
    @include rotate(180deg);
  }
}
