/* API EDIT ICON */
#api {
  .api-filter .material-icons {
    right: 48px;
  }
}

/* API LIST STYLES */

aio-api-list {
  div.form-search i.material-icons {
    width: 20px;
    pointer-events: none;
  }

  .form-search input {
    width: 182px;

    @media screen and (max-width: 600px) {
      width: 100%;
    }
  }

  .api-list-container {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    padding-left: 0;

    h2 {
      margin-top: 16px;
    }
  }
}

.api-filter {
  display: flex;
  margin: 0 auto;

  @media (max-width: 600px) {
    flex-direction: column;
    margin: 16px auto;
  }

  .form-select-menu,
  .form-search {
    margin: 8px;

    @media screen and (max-width: 600px) {
      margin-left: 0;
    }
  }

  aio-select:first-child .form-select-menu {
    margin-left: 0;
  }
}

/* LAYOUT */

.docs-content {
  position: relative;
}

.l-content-small {
  padding: 16px;
  max-width: 1100px;
  margin: 0;

  @media handheld and (max-width: $phone-breakpoint),
  screen and (max-device-width: $phone-breakpoint),
  screen and (max-width: $tablet-breakpoint) {
    padding: 24px 0 0;
  }
}

/* SEARCH BAR */

.form-search {
  position: relative;

  input {
    box-shadow: 0 2px 2px rgba($black, 0.24), 0 0 2px rgba($black, 0.12);
    box-sizing: border-box;
    border: 1px solid $white;
    border-radius: 4px;
    color: $darkgray;
    @include font-size(14);
    @include line-height(32);
    outline: none;
    padding: 4px 16px 4px 32px;
    transition: all .2s;

    @include placeholder {
      @include font-size(14);
      color: $mediumgray;
    }

    &:focus {
      border: 1px solid $blue-400;
      box-shadow: 0 2px 2px rgba($blue-400, 0.24), 0 0 2px rgba($blue-400, 0.12);
    }
  }

  .material-icons {
    color: $blue-grey-100;
    @include font-size(20);
    left: 8px;
    position: absolute;
    top: 12px;
    z-index: $layer-1;
  }
}

/* API SYMBOLS */

/* SYMBOL CLASS */

.symbol {
  border-radius: 2px;
  box-shadow: 0 1px 2px rgba($black, .24);
  color: $white;
  display: inline-block;
  @include font-size(10);
  font-weight: 600;
  @include line-height(16);
  text-align: center;
  width: 16px;

  // SYMBOL TYPES
  // Symbol mapping variables in *constants*
  @each $name, $symbol in $api-symbols {
    &.#{$name} {
      background: map-get($symbol, background);

      &:before {
        content: map-get($symbol, content);
      }
    }
  }
}

/* API HOMEE PAGE */

/* API FILTER MENU */

.api-filter {
  aio-select {
    width: 200px;

    @media screen and (max-width: 600px) {
      width: 100%;
    }

    .symbol {
      margin-right: 8px;
    }
  }

  .form-search {
    float: left;
  }
}

/* API CLASS LIST */

.docs-content .api-list {
  list-style: none;
  margin: 0 0 32px -8px;
  padding: 0;
  overflow: hidden;

  @media screen and (max-width: 600px) {
    margin: 0 0 0 -8px;
  }

  li {
    @include font-size(14);
    margin: 8px 0;
    @include line-height(14);
    padding: 0;
    float: left;
    width: 33%;
    overflow: hidden;
    min-width: 220px;
    text-overflow: ellipsis;
    white-space: nowrap;

    .symbol {
      margin-right: 8px;
    }

    a {
      color: $blue-grey-600;
      display: inline-block;
      @include line-height(16);
      padding: 0 16px 0;
      text-decoration: none;
      transition: all .3s;
      overflow: hidden;
      text-overflow: ellipsis;

      &:hover {
        background: $blue-grey-50;
        color: $blue-500;
      }

      &.deprecated-api-item {
        text-decoration: line-through;
      }
    }
  }
}

.docs-content .h2-api-docs,
.docs-content .h2-api-docs:first-of-type {
  @include font-size(18);
  @include line-height(24);
  margin-top: 0;
}

.code-links {
  a {
    code, .api-doc-code {
      color: #1E88E5 !important;
    }
  }
}

.openParens {
  margin-top: 15px;
}

.endParens {
  margin-bottom: 20px !important;
}

p {
  &.selector {
    margin: 0;
  }

  &.location-badge {
    margin: 0 0 16px 16px !important;
  }

  .api-doc-code {
    border-bottom: 0;

    :hover {
      border-bottom: none;
    }
  }
}

.row-margin {
  margin-bottom: 36px;
  h2 {
    @include line-height(28);
  }
}

.code-margin {
  margin-bottom: 8px;
}

.no-bg {
  background: none;
  padding: 0;
}

.no-bg-with-indent {
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 16px;
  margin-top: 6px;
  margin-bottom: 0;
  background: none;
}

.code-background {
  padding: 0 5px 0;

  span.pln {
    color: #1E88E5 !important;
  }
}

.code-anchor {
  cursor: pointer;
  text-decoration: none;
  font-size: inherit;

  &:hover {
    text-decoration: underline;
  }
}

.api-doc-code {
  @include font-size(14);
  color: #1a2326;

  // the last .pln (white space) creates additional spacing between sections of the api doc. Remove it.
  &.no-pln {
    .pln:last-child {
      display: none;
    }
  }
}

@media screen and (max-width: 600px) {
  .docs-content {
    // Overrides display flex from angular material.
    // This was added because Safari doesn't play nice with layout="column".
    // Look of API doc in Chrome and Firefox remains the same, and is fixed for Safari.
    .layout-xs-column {
      display: block !important;
    }
  }

  .api-doc-code {
    @include font-size(12);
  }

  p.location-badge {
    position: relative;
    @include font-size(11);
  }
}
