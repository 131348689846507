.card-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 16px 0;

    .docs-card {
      @include card(194px, 30%);
      max-width: 340px;
      min-width: 262px;
      margin: 24px 8px;
      padding-bottom: 48px;
      position: relative;

      @media screen and (max-width: 600px) {
        width: 100%;
        margin: 8px auto;
        max-width: none;
      }

      &:hover {
        text-decoration: none;

        section {
          color: $blue;
        }

        p {
          color: $darkgray;
          padding: 0 16px;
        }

        .card-footer {
          @include line-height(32);
          padding: 8px 16px;
          background-color: rgba($blue, 0.1);
          color: $blue;
        }
      }

      section {
        color: $deepgray;
        @include font-size(20);
        @include line-height(24);
        margin: 0;
        padding: 32px 0 24px;
        text-transform: none;
        text-align: center;
      }

      p {
        color: $darkgray;
        @include font-size(13);
        @include line-height(24);
        padding: 0 16px;
        margin: 0;
        text-align: center;
      }

      .card-footer {
        bottom: 0;
        border-top: 0.5px solid $lightgray;
        box-sizing: border-box;
        @include line-height(48);
        left: 0;
        position: absolute;
        right: 0;
        text-align: right;
        color: $mediumgray;

        a {
          color: $mediumgray;
          @include font-size(13);
        }
      }

      .card-footer.center {
        text-align: center;
      }
    }
}

.card-section {
  @include card(auto, 90%);
  padding: 16px 32px;
  margin: 16px 0;
  display: flex;
  flex-direction: row;
  align-items: center;

  // Removes on-hover effect from card mixin
  &:hover {
    box-shadow: 0 2px 2px rgba($black, 0.24), 0 0 2px rgba($black, 0.12);
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 8px 0;
  }

  a,
  .button,
  button {
    text-align: center;
  }

}
