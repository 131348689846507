/* SELECT MENU */

.form-select-menu {
  position: relative;
}

.form-select-button {
  background: $white;
  box-shadow: 0 2px 2px rgba($black, 0.24), 0 0 2px rgba($black, 0.12);
  box-sizing: border-box;
  border: 1px solid $white;
  border-radius: 4px;
  color: $blue-grey-600;
  @include font-size(14);
  font-weight: 400;
  @include line-height(32);
  outline: none;
  padding: 4px 16px;
  text-align: left;
  width: 100%;
  cursor: pointer;
  display: flex;
  align-items: center;
  flex-direction: row;

  strong {
    font-weight: 600;
    margin-right: 8px;
    text-transform: capitalize;
  }

  &:focus {
    border: 1px solid $blue-400;
    box-shadow: 0 2px 2px rgba($blue-400, 0.24), 0 0 2px rgba($blue-400, 0.12);
  }

  &[disabled] {
    color: lightgrey;
    cursor: not-allowed;
  }
}

.form-select-dropdown {
  background: $white;
  box-shadow: 0 16px 16px rgba($black, 0.24), 0 0 16px rgba($black, 0.12);
  border-radius: 4px;
  list-style-type: none;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: $layer-2;

  li {
    cursor: pointer;
    @include font-size(14);
    @include line-height(32);
    margin: 0;
    padding: 4px 16px 4px 40px;
    position: relative;
    transition: all .2s;
    border: 1px solid transparent;

    &:first-child {
      border-radius: 4px 4px 0 0;
    }

    &:last-child {
      border-radius: 0 0 4px 4px;
    }

    &:hover {
      background: $blue-grey-50;
      color: $blue-500;
    }

    &.selected {
      background-color: $blue-grey-100;
    }

    .symbol {
      left: 16px;
      position: absolute;
      top: 12px;
      z-index: $layer-5;
    }
  }
}
