.sidenav-container {
  width: 100%;
  height: 100vh;
}

.sidenav-content {
  min-height: 100vh;
  padding: 80px 3rem 2rem;

  @media screen and (max-width: 600px) {
    min-height: 450px;
    padding: 80px 2rem 1rem;
  }

  @media screen and (max-width: 480px) {
    padding: 80px 1rem 1rem;
  }

  aio-shell.page-docs & {
    // padding: 6rem 3rem 3rem 3rem; // THIS CAUSES THE TOP NAV TOOLBAR TO JUMP BETWEEN DOCS AND OTHER PAGES
    margin: auto;
  }

  button {
    min-width: 24px;
  }
}

aio-menu {
  @media (max-width: 600px) {
    display: none;
  }
}

#guide-change-log h2::before {
  content: "";
  display: block;
  height: 1px;
  margin: 24px 0px;
  background: $lightgray;
}
